'use strict'

window.__ = {
  endpoints: {
    base: 'https://api.qua.gmusic.g.globo',
    ingest: 'https://api.qua.gmusic.g.globo/ingest-service/v1',
    work: 'https://api.qua.gmusic.g.globo/work-service/v1',
    musician: 'https://api.qua.gmusic.g.globo/musician-service/v1',
    phonogram: 'https://api.qua.gmusic.g.globo/phonogram-service/v1',
    user: 'https://api.qua.gmusic.g.globo/user-service/v1',
  },
  auth: {
    token_base_url: 'entretenimento-qua.auth.us-east-1.amazoncognito.com',
    authorize_base_url: 'https://entretenimento-qua.auth.us-east-1.amazoncognito.com/oauth2/authorize',
    logout_base_url: 'https://entretenimento-qua.auth.us-east-1.amazoncognito.com/logout',
    api_key: 'yJkrIo23v93bLCc8hhbdJ4EvQs5BLwk69zl55fTX',
    scope: 'email+openid+aws.cognito.signin.user.admin+profile',
    client_id: '77lhsh19vb21nvifeq9447u4p6',
    authorization_key:
      'NzdsaHNoMTl2YjIxbnZpZmVxOTQ0N3U0cDY6MTAya203dGoyc2YwbjBiaDRnbzd1a3BmMmkycGpvYms4amJvOG1sbDJnNzllZGRrZWZ2aQ==',
  },
  moodsMaxItems: 3,
  genresMaxItems: 3,
}
